import "./App.css";
import { useMemo } from "react";

import MainNavbar from './MainNavbar.js'
import Main from "./Main.js";

import { useLocation } from 'react-router-dom'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faBookOpen, faFlask, faFilm, faCity, faBomb, faGamepad, faBook, faTimes, faExclamationTriangle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';

// import * as anchor from "@project-serum/anchor";
// import { clusterApiUrl } from "@solana/web3.js";
// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
// import {
//   getPhantomWallet,
//   // getSlopeWallet,
//   getSolflareWallet,
//   getSolletWallet,
// } from "@solana/wallet-adapter-wallets";

// import {
//   ConnectionProvider,
//   WalletProvider
// } from "@solana/wallet-adapter-react";

// import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";


library.add(faHome, faBookOpen, faFlask, faFilm, faCity, faBomb, faGamepad, faBook, faTimes, faChevronDown, faEye, faExclamationTriangle, faTwitter, faDiscord)

// const treasury = new anchor.web3.PublicKey(
//   process.env.REACT_APP_TREASURY_ADDRESS!
// );

// const config = new anchor.web3.PublicKey(
//   process.env.REACT_APP_CANDY_MACHINE_CONFIG!
// );

// const candyMachineId = new anchor.web3.PublicKey(
//   process.env.REACT_APP_CANDY_MACHINE_ID!
// );

// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
// const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)


const App = () => {
  // const endpoint = useMemo(() => clusterApiUrl(network), []);

  // const wallets = useMemo(
  //   () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet()],
  //   []
  // );


  const location = useLocation();
  const links = { discord: 'https://discord.gg/pvsgf9s4', twitter: 'https://twitter.com/bobosofwar' }
  let series = [{ title: 'Previews', icon: ['far', 'eye'], description: '', link: '/series/previews' }, { title: 'Wars', icon: 'bomb', description: '', link: '/series/wars' }, { title: 'Civilizations', icon: 'city', description: '', link: '/series/civilizations' }]

  return (
    <>
      <MainNavbar currentPage={location.pathname} links={links} />
      <Main
        links={links}
        series={series}
        // candyMachineId={candyMachineId}
        // config={config}
        // connection={connection}
        startDate={startDateSeed}
        // treasury={treasury}
        txTimeout={txTimeout}
      />
    </>
    // <ConnectionProvider endpoint={endpoint}>
    //   <WalletProvider wallets={wallets}>
    //     <WalletDialogProvider>
    //       <MainNavbar currentPage={location.pathname} links={links} />
    //       <Main
    //         links={links}
    //         series={series}
    //         candyMachineId={candyMachineId}
    //         config={config}
    //         connection={connection}
    //         startDate={startDateSeed}
    //         treasury={treasury}
    //         txTimeout={txTimeout}
    //       />
    //     </WalletDialogProvider>
    //   </WalletProvider>
    // </ConnectionProvider>
  );
};

export default App;
