import React from "react";
import "./ConstructionMinter.css"
import ConstructionLabVideoMP4 from './images/lab/labUnderConstruction.mp4'
import ConstructionLabVideoOGV from './images/lab/labUnderConstruction.ogv'
import ConstructionLabVideoWEBM from './images/lab/labUnderConstruction.webm'
import labDoorLeft from "./images/lab/labUnderConstructionLeft.webp"
import labDoorRight from "./images/lab/labUnderConstructionRight.webp"
import LabVideoMP4 from './images/lab/plainLabVid.mp4'
import LabVideoOGV from './images/lab/plainLabVid.ogv'
import LabVideoWEBM from './images/lab/plainLabVid.webm'

import { gsap } from "gsap";

class ConstructionMinter extends React.Component {
    // onComponentDidMount() {
    //     // this.videoConstruction.current.play();
    //     // this.videoLab.current.play();
    // }

    // constructor(props) {
    //     super(props)
    //     // this.videoConstruction = React.createRef();
    //     // this.videoLab = React.createRef();
    // }

    componentDidMount() {
        setTimeout(() => this.enterLab(), 500)
    }

    enterLab() {
        let enterLabTl = gsap.timeline()
        enterLabTl.set("#enter-lab-btn", {visibility: "hidden"});
        enterLabTl.set("#entry-vid", {visibility: "hidden"});
        enterLabTl.add("doorsOpen", 0);

        enterLabTl.to("#lab-door-right", {scale: 1.01, xPercent: 0.5, duration: 0.5}, "doorsOpen");
        enterLabTl.to("#lab-door-left", {scale: 1.01, xPercent: -0.5, duration: 0.5}, "doorsOpen");
        enterLabTl.to("#lab-door-left", {xPercent: -100, duration: 3.25, ease: "power2.in"}, ">");
        enterLabTl.to("#lab-door-right", {xPercent: 100, duration: 3.25, ease: "power2.in"}, '<');

        // When out of viewport, disable visibility
        enterLabTl.to(".lab-door", {visibility: "hidden"}, '>');

        enterLabTl.to("#lab-door-right", {boxShadow: "-10px 0px 15px -8px #000000", duration: 0.75}, 'doorsOpen+=0.5');
        enterLabTl.to("#lab-door-left", {boxShadow: "10px 0px 15px -8px #000000", duration: 0.75}, 'doorsOpen+=0.5');
        

        
    }

    render() {
        return(
            <div id='mint-container' className='container-fluid vh-100'>
                <div className='row pb-3'>
                    <div id='lab-div' className='position-absolute p-0' style={{width: '100vw', height: '100vh', overflow: "hidden"}}>
                        <video id='vid' className='position-absolute d-block m-auto' style={{width: "100%", height: "100%"}} loop autoPlay playsInline muted>
                            <source src={LabVideoMP4} type='video/mp4' />
                            <source src={LabVideoWEBM} type='video/webm' />
                            <source src={LabVideoOGV} type='video/ogg' />
                        </video>
                        
                        <img src={labDoorRight} id='lab-door-right' className='lab-door position-absolute' style={{right: 0}} alt='lab-right-door' />
                        <img src={labDoorLeft} id='lab-door-left' className='lab-door position-absolute' style={{left: 0}} alt='lab-left-door' />
                        <video id='entry-vid' className='position-absolute d-block m-auto' style={{width: "100%", height: "100%"}} loop autoPlay playsInline muted>
                            <source src={ConstructionLabVideoMP4} type='video/mp4' />
                            <source src={ConstructionLabVideoWEBM} type='video/webm' />
                            <source src={ConstructionLabVideoOGV} type='video/ogg' />
                        </video>
                        {/* <button id='enter-lab-btn' className='position-absolute btn' onClick={this.enterLab}></button> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default ConstructionMinter;
