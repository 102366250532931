import React from "react";
import './GameInfo.css'
import Banner from './GameInfoComponents/Banner.js';
import Presentation from './GameInfoComponents/Presentation.js';
import Weapons from './GameInfoComponents/Weapons.js';
import TokenContainer from './GameInfoComponents/TokenContainer.js';
import WPBanner from './GameInfoComponents/WPBanner.js';
import RoadMap from './GameInfoComponents/Roadmap.js';

import MainFooter from "./MainFooter.js";

import trainingCampIsoImage from './images/gamification/trainingCampIso.webp';
import hibernationIsoImage from './images/gamification/hibernationIso.webp';
import cityBuildingIsoImage from './images/gamification/cityBuilding.webp';



class GameInfo extends React.Component {

    render() {
        let presentationBanners = [
            {id: 'capitals-presentation', header: 'Capital Cities', imageIdle: cityBuildingIsoImage, text: "Capital cities are the main hub of each continent. They host a variety of buildings including the Bank, the Governmental Palace, the Common Hall as well as the Casino hotel and Bobo houses. Players can travel to their Bobo's capital city in order to access the diverse functionalities they encompass."},
            {id: 'training-presentation', header: 'Training Camps', imageIdle: trainingCampIsoImage, text: "Training camps are military facilities where Bobo owners can play mini-games in order to upgrade their Bobo's combat level. After playing the minigame, the Bobo is sent for a chosen amount of days to \"camp\". Once camp has finished, the owner can retrieve their bobo with the upgraded combat level."},
            {id: 'hibernation-presentation', header: 'Hibernation', imageIdle: hibernationIsoImage, style: {marginBottom: '13rem'}, text: "The hibernation station is a 3D lab located on each continent. Travel with your Bobo to this secret scientific building and place your Bobo in a hibernation pod in order to stake the NFT! Have a tour of the different hibernation pods which provide various staking options."},
        ]
        return(
            <div id='game-info-page'>
                <Banner />
                <Presentation presentationBanners={presentationBanners} />
                <Weapons />
                <TokenContainer />
                <RoadMap />
                <WPBanner />
                <MainFooter links={this.props.links} />
            </div>
        )
    }
}

export default GameInfo;