import sketch1Img from './images/series/sketches/drawing1.webp'
import sketch2Img from './images/series/sketches/drawing2.webp'
import sketch3Img from './images/series/sketches/drawing3.webp'
import sketch4Img from './images/series/sketches/drawing4.webp'
import sketch5Img from './images/series/sketches/drawing5.webp'
import sketch6Img from './images/series/sketches/drawing6.webp'
import sketch7Img from './images/series/sketches/drawing7.webp'
import sketch8Img from './images/series/sketches/drawing8.webp'
import sketch9Img from './images/series/sketches/render1.webp'
import sketch10Img from './images/series/sketches/render11.webp'
import sketch11Img from './images/series/sketches/render3.webp'
import sketch12Img from './images/series/sketches/render4.webp'
import sketch13Img from './images/series/sketches/render5.webp'
import sketch14Img from './images/series/sketches/render9.webp'
import sketch15Img from './images/series/sketches/render10.webp'
import sketch16Img from './images/series/sketches/render8.webp'

import Tooltip from 'react-bootstrap/Tooltip';

export const sketchImagesContainer1 = [sketch1Img, sketch2Img, sketch3Img, sketch4Img, sketch5Img, sketch6Img, sketch7Img, sketch8Img]
export const sketchImagesContainer2 = [sketch9Img, sketch10Img, sketch11Img, sketch12Img, sketch13Img, sketch14Img, sketch15Img, sketch16Img]
export const disclaimerIndexes = []

export const disclaimerTooltip = (props) => (
    <Tooltip id="disclaimer-tooltip" {...props}>
        This asset is not part of the collection nor an original work from our artists.
    </Tooltip>
);