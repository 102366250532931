import React from "react";
import './WPBanner.css';

import {splitText, addSecretTextAnimation} from '../utils.js';

import { gsap } from "gsap";

class WPBanner extends React.Component {
    componentDidMount() {
        let secretTextTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#wp-container",
                start: 'top 60%',
            }
        })

        addSecretTextAnimation(secretTextTl, '.coming-soon.text-secret-hide', 0.2, "coming-soon", '<0.03')
    }

    openWP() {
        window.open("https://bobos-war.gitbook.io/bobos-of-war-b.o.w/bobos-of-war/introduction")
    }

    render() {
        return(
            <div id='wp-container' className="d-flex flex-column position-relative justify-content-center align-items-center">
                <h1 className='sub-title text-ussr text-white'>WHITE PAPER</h1>
                <button onClick={this.openWP} className='btn no-decoration selected-gradient text-white'><h2>View Gitbook</h2></button>
                {/* <div className='d-flex position-absolute justify-content-center align-items-center' style={{width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.5)"}}>
                    <h1 className='title text-ussr text-white'>{splitText('COMING SOON', 'coming-soon text-secret-hide')}</h1>
                </div> */}
            </div>
        )
    }
}

export default WPBanner;