import React from 'react';
import './MainFooter.css'

import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import flagImg from './images/flags/transparent_white_flag.svg';

class MainFooter extends React.Component {
    render() {
        return(
            <div id='footer' className='bg-dark-0'>
                <div id='footer-content' className='container-fluid d-flex flex-column align-items-center'>
                    <div className='row col-11 col-md-8 col-lg-6 col-xl-4 d-flex flex-column align-items-center'>
                        <img src={flagImg} className='col-12' alt='War Flag' style={{maxHeight: '100px'}} />
                        <Nav className='col-12 d-flex justify-content-center mt-3 flex-nowrap'>
                            <div className='mx-3 p-0'><Nav.Link href="/home" className='p-0'><h3 className='text-ussr footer-link'>Home</h3></Nav.Link></div>
                            <div className='mx-3 p-0'><Nav.Link href="/gamification" className='p-0'><h3 className='text-ussr footer-link'>Game</h3></Nav.Link></div>
                            <div className='mx-3 p-0'><Nav.Link href="/origins" className='p-0'><h3 className='text-ussr footer-link'>Origins</h3></Nav.Link></div>
                            <div className='mx-3 p-0'><Nav.Link href="/lab" className='p-0'><h3 className='text-ussr footer-link'>Lab</h3></Nav.Link></div>
                        </Nav>
                        <div className='col-12 d-flex justify-content-center mt-3'>
                            <a type='twitter' href={this.props.links.twitter} className='twitter-link pb-2 pb-0 mx-5' target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'twitter']} className='twitter-logo bg-dark-0' style={{fontSize: '2.5em'}} /></a>
                            <a type='discord' href={this.props.links.discord} className='discord-link pb-2 pb-0 mx-5' target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'discord']} className='discord-logo bg-dark-0' style={{fontSize: '2.5em'}} /></a>
                        </div>
                    </div>
                    <p className='align-self-center' style={{marginTop: "2rem", marginBottom: "3rem"}}><span className='text-ussr'>For partnerships contact:</span><b /> <b>bobosofwar@gmail.com</b></p>
                </div>
            </div>
        )
    }
}

export default MainFooter;