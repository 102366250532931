import React from "react";
import './Presentation.css'

import {splitText, addSecretTextAnimation} from '../utils.js';
import arenaImage from "../images/gamification/arenaIllu.webp"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

class Presentation extends React.Component {
    componentDidMount() {

        let secretTextTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#main-presentation",
                start: 'top 60%',
            }
        })

        addSecretTextAnimation(secretTextTl, '.game-title.text-secret-hide', 0.2, "game-title", '<0.03')

        let presentationNames = this.props.presentationBanners.map(banner => banner.header)

        let presentationContainers = gsap.utils.toArray('.presentation-info-banner');
        let presentationHeaders = gsap.utils.toArray('.presentation-info-header');
        let presentationBodies = gsap.utils.toArray('.presentation-info-body');
        let presentationImages = gsap.utils.toArray('.presentation-info-image');
        let presentationSeparators = gsap.utils.toArray('.presentation-info-seperator');
        presentationContainers.forEach((container, index) => {
            let presentationTl = gsap.timeline({
                scrollTrigger: {
                    trigger: container,
                    start: 'top 60%',
                    // end: 'bottom 90%',
                },
                defaults: {ease: "back"}
            })
            

            let writeHeaderTl = gsap.timeline()
            writeHeaderTl.to(presentationHeaders[index], {duration: 0.8, text: presentationNames[index]});
            presentationTl.add(writeHeaderTl)
            if(index % 2 !== 0) {
                presentationTl.from(container, {backgroundColor: '#272727', ease: "none"}, '<')
            }
            presentationTl.fromTo(presentationImages[index], {opacity: 0}, {opacity: 1, duration: 2}, '<+0.5');
            presentationTl.from(presentationBodies[index], {xPercent: 5, opacity: 0}, '<+0.3')
            presentationTl.from(presentationSeparators[index], {height: 0, duration: 1}, '<+0.4')
        })


        // Arena Banner
        let arenaTl = gsap.timeline({
            scrollTrigger: {
                trigger: '#arena-container',
                start: 'top 35%',
                // end: 'top 10%',
                // scrub: true,
                // markers: true
            }
        })
        arenaTl.from('#arena-text', {top: '50%', left: '35%', transformOrigin: "-50% -50%", duration: 1});
        addSecretTextAnimation(arenaTl, '#arena-text .coming-soon span', 0.1, "", '<0.02')
        arenaTl.from('#arena-text .text', {xPercent: 5, opacity: 0}, '<+0.1')
    }


    constructor(props) {
        super(props)
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(TextPlugin);
        gsap.registerPlugin(CSSRulePlugin);
    }

    
    
    render() {
        return(
            <div id='presentation-container' className='container-fluid row d-flex justify-content-center mx-0 px-0'>
                <div id='main-presentation' className='presentation-info col-12 row d-flex justify-content-center'>
                    <div className='col-12 col-lg-8 col-xl-6 d-flex flex-column align-items-center justify-content-center'>
                        <h1 className='title text-ussr text-center'>{splitText('The Bobo Kingdom', 'game-title text-secret-hide')}</h1>
                        <h4 className='text-center'>After conquering humanity during the Purging war, Bobos starting fighting against the only thing that was left, themselves. 4 Bobo civilizations emerged on 4 different continents, marking the start of the everlasting conflicts in the kingdom.</h4>
                    </div>
                </div>
                {this.props.presentationBanners.map((banner, index) => {
                    let bannerStyle;
                    if(index % 2 === 0) {
                        bannerStyle = {
                            textContainer: 'align-items-center align-items-lg-end order-last ml-auto mr-3',
                            paragraph: 'text-lg-right',
                            separator: '',
                            imageContainer: 'order-first'
                        }
                    } else {
                        bannerStyle = {
                            textContainer: 'align-items-center align-items-lg-start order-last order-lg-first mr-auto ml-3',
                            paragraph: 'text-lg-left',
                            separator: 'presentation-info-seperator-light',
                            imageContainer: 'order-first order-lg-last'
                        }
                    }
                    return(
                        <div id={banner.id} key={index} className='presentation-info-banner col-12 row d-flex mx-0 py-5' style={banner.style}>
                            <div className={`col-12 col-lg-5 d-flex flex-column justify-content-center ${bannerStyle.textContainer} `}>
                                <h1 className='title text-ussr'><span className='presentation-info-header'></span></h1>
                                <p className={`presentation-info-body mx-lg-5 ${bannerStyle.paragraph}`}>{banner.text}</p>
                            </div>
                            <div className={`presentation-info-seperator ${bannerStyle.separator} d-none d-lg-block order-2 col-1`}></div>
                            <div className={`col-12 col-lg-6 presentation-image-container d-flex justify-content-center ${bannerStyle.imageContainer}`}>
                                <img src={banner.imageIdle} className='presentation-image-idle position-relative' />
                            </div>
                        </div>
                    )
                })}
                <div id='arena-container' className='col-12 px-0'>
                    <div id='arena-banner' className='d-flex justify-content-center px-0'>
                        <img className='d-block m-auto' src={arenaImage} />
                    </div>
                    <div id='arena-text' className='d-flex flex-column mt-5 ml-5'>
                        <h1 className='title text-ussr mb-0'>The Arena</h1>
                        <h5 className='coming-soon ml-4'>{splitText("COMING SOON", "text-secret-hide")}</h5>
                        <p className='text col-10 col-md-7 col-lg-5 ml-3'>The arena battles are 10 player fps matches where Bobos play against one another to complete the objective or kill the most enemies. Play with your weapon NFTs in a variety of game modes such as deathmatch, king of the arena, capture the Bobo... Play in chill free games or compete in paid entry matches to earn the sum of fees! </p>
                    </div>
                </div>
            </div>

        )
    }
}

export default Presentation;