import React from "react";
import './AnimatedSeries3.css'
import boboTeaserFrame from './images/bobos/closeupRender.jpg'
// import boboTeaser from './images/bobo_teaser.mp4'
import MainFooter from './MainFooter.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {splitText, shuffleArray, getBSsize} from './utils.js';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {sketchImagesContainer1, sketchImagesContainer2, disclaimerIndexes, disclaimerTooltip} from "./sketchesImport.js"

class AnimatedSeries extends React.Component {

    componentDidMount() {
        let screenBSsize = getBSsize(window.innerWidth)
        gsap.to('#scroll-arrow', {scrollTrigger: {target: '#animated-series-page > div', start: '50% top'}, opacity: 0, duration: 0.5})

        let titleTl = gsap.timeline()
        let shuffledTitleCharacters = shuffleArray(gsap.utils.toArray('.page-header.text-secret-hide'))
        shuffledTitleCharacters.forEach((char, index) => {
            var tl = new gsap.timeline();
            tl.set(char, { className: "page-header text-secret-hide state-1" })
                .set(char, { delay: 0.15, className: "page-header text-secret-hide state-2" })
                .set(char, { delay: 0.15, className: "page-header text-secret-hide state-3" })

                titleTl.add(tl, index * 0.05);
        })

        let scrollTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#animated-series-page",
                start: 'top top',
                end: '+=2000px',
                scrub: 1,
                pin: '#animated-series-page',
            }
        })

        scrollTl.to("#main-background", {opacity: 0, duration: 20})
        scrollTl.from("#sketches", {scale: 0, opacity: 0, borderRadius: "20rem", duration: 20})
        // gsap.to(window, {duration: 2, scrollTo: {y: 3000}, 'ease': "out"}, '+=10%')

        let sketchesHeaderTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#sketches",
                start: "+=500px",
                end: "+=0",
            }
        })
          
        let shuffledSketchesCharacters = shuffleArray(gsap.utils.toArray('.sketches-header.text-secret-hide'))
        shuffledSketchesCharacters.forEach((char, index) => {
            var tl = new gsap.timeline();
            tl.set(char, { className: "sketches-header text-secret-hide state-1" })
                .set(char, { delay: 0.2, className: "sketches-header text-secret-hide state-2" })
                .set(char, { delay: 0.2, className: "sketches-header text-secret-hide state-3" })

                sketchesHeaderTl.add(tl, index * 0.05);
        })

        const sizeMultiplier = {"xxl": 1, "xl": 1, "lg": 1, "md": 1.7, "sm": 3, "xs": 3}
        scrollTl.fromTo("#sketches-container-1", {xPercent: 100, rotation: -15}, {xPercent: sizeMultiplier[screenBSsize]*-100, rotation: 0, ease: "Power1.easeInOut", duration: 40}, ">")
        scrollTl.fromTo("#sketches-container-2", {xPercent: -100, rotation: 15}, {xPercent: sizeMultiplier[screenBSsize]*100, rotation: 0, ease: "Power1.easeInOut", duration: 40}, '<')

        scrollTl.to("#transition-sketch", {scale: 2}, '>')
    }

    constructor(props) {
        super(props)
        this.state = {
            showSketch: null
        }
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(TextPlugin);
        gsap.registerPlugin(ScrollToPlugin);
    }

    scrollDown() {
        const y = 3400 + window.pageYOffset - window.innerHeight*250/900;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
    
    render() {
        return(
            <div id='animated-series-page' className='container-fluid px-0' style={{top: 0, width: '100%'}}>
                <div className='position-relative' style={{overflow: 'hidden'}}>
                    <div id='scroll-arrow' className='position-absolute mb-2 mx-auto' onClick={() => this.scrollDown()} style={{bottom: "15%"}}><FontAwesomeIcon icon="chevron-down" size='4x' /></div>
                    <div id='main-background' className='d-flex justify-content-center align-items-center'>
                        <div className="background-img d-flex justify-content-start align-items-start">
                            <img src={boboTeaserFrame} className='d-block m-auto' alt="home banner"/>
                        </div>
                        <div className='wrapper d-flex justify-content-center ml-4'>
                            <h1 id='title' className='text-ussr text-center' style={{fontSize: '10vw', fontWeight: '600', lineHeight: 1}}>{splitText('ANIMATED', 'page-header text-secret-hide')}<br/>{splitText('SERIES', 'page-header text-secret-hide')}</h1>
                        </div>
                    </div>
                    <div id='sketches' className='d-flex flex-column position-absolute justify-content-center align-items-start'>
                        <h1 className='text-ussr text-center ml-4' style={{fontSize: '6vw', fontWeight: '500'}}>{splitText('Sketches', 'sketches-header text-secret-hide')}</h1>
                        <div className='d-flex flex-column justify-content-around'>
                            <div id="sketches-container-1" className='d-flex sketches-container mb-3'>
                                {sketchImagesContainer1.map(image => {
                                    return(
                                        <div className='col-6 col-md-4 col-lg-3 film'>
                                            <LazyLoadImage src={image} effect="blur" className='sketch-img film__frame' onClick={() => this.setState({showSketch: image})} wrapperProps={{style: {width: "100%", height: "100%"}}} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div id="sketches-container-2" className='d-flex sketches-container justify-content-end' style={{float: "right"}}>
                                {sketchImagesContainer2.map((image, index) => {
                                    return(
                                        <div className='col-6 col-md-4 col-lg-3 film'>
                                            <LazyLoadImage src={image} effect="blur" id={index === 1 && "transition-sketch"} className='sketch-img film__frame' onClick={() => this.setState({showSketch: image})} wrapperProps={{style: {width: "100%", height: "100%"}}} />
                                            {disclaimerIndexes.includes(index) && 
                                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={disclaimerTooltip}>
                                                <button className='position-absolute mt-1 mr-4' style={{top: 0, right: 0, fontSize: '1.2rem', background: "transparent", color: "#ffff00", border: "none"}}><FontAwesomeIcon icon='exclamation-triangle'  /></button>
                                            </OverlayTrigger>}
                                        </div>
                                    )
                                })}
                            </div>
                            <Modal show={this.state.showSketch !== null} size="lg" centered onHide={() => this.setState({showSketch: null})}>
                                <Modal.Body className='p-0'>
                                    <FontAwesomeIcon icon='times' size="2x" className="position-absolute mr-3 mt-3 close-modal-icon" style={{top: 0, right: 0}} onClick={() => this.setState({showSketch: null})} /> 
                                    <img src={this.state.showSketch} className='sketch-img-full' style={{width: '100%', height: 'auto'}} alt='sketch' />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                <MainFooter links={this.props.links}  />
            </div>
        )
    }
}

export default AnimatedSeries;