import React, { useEffect } from "react";
import "./Questions.css";
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Questions = () => {
    gsap.registerPlugin(ScrollTrigger);
    let faqs = [{question: 'What is the release date and price?', answer: <p>To be determined.</p>}, 
                {question: 'How can I mint a Bobo?', answer: <span>Bobo minting is incredibly simple. Just connect your wallet and follow the instructions. <ol><li>Download the Phantom wallet extension (We recommend: Brave or Google Chrome).</li><li>Buy Solana ($SOL) on an exchange (FTX, FTX US, Coinbase, Binance, Bybit) and send it to your wallet's Public Address.</li><li>By clicking on the connect button in the laboratory workbench, you can connect your Wallet to our website.</li><li>Once connected, click the mint button and then click confirm on your wallet.</li></ol></span>}, 
                {question: 'Is there a mint limit?', answer: <p>Each transaction is limited to one mint. However you can repeat the procedure as often as you like.</p>},
                {question: 'Which Solana wallet can I use?', answer: <p>You will be able to connect with: Phantom, Sollet and Solflare.</p>},
                {question: 'How are Bobos generated?', answer: <p>All Bobos and their traits are drawn by hand and algorithmically generated using custom code. There are 300+ attributes spread across 4 different Military Branches in which mutants can appear. In order to distribute Bobos, we will be using the Metaplex Candy Machine to ensure best security and greatest user experience.</p>},
                {question: 'Is each Bobo unique?', answer: <p>Yes, each Bobo will be unique, we have made sure via our custom generative algorithm that no two NFTs can be identical.</p>},                
                {question: 'Secondary market?', answer: <p>Once the collection is sold out, Bobos are going to be listed on the main Solana marketplaces.</p>}, 
                {question: 'How rare is my Bobo?', answer: <p>Our own rarity tool will be released on our website shortly after the whole collection sells out.</p>},
                {question: 'Wen Moon?', answer: <p>There is a slight chance that Bobos decide to annihilate the planet earth and fly to a new Planet. (The future of the storyline will change accordingly to what is decided by the community).</p>}, 
                {question: 'Can I mint on Mobile?', answer: <p>Depending on when we launch, Phantom's wallet app might be available for you to use to mint Bobos.</p>}]

    useEffect(() => {
        let accordionTl = gsap.timeline({
            scrollTrigger: {
                trigger: '#questions-answers .row', 
                start: 'top 85%'
            },
            defaults: {duration: 0.6, ease: 'back'}

        })
        gsap.utils.toArray('.accordion-item').forEach(item => {
            accordionTl.from(item, {yPercent: 100, opacity: 0}, '<+0.15')
        })
    }, []);


    return(
        <div id='questions-answers' className='container-fluid bg-dark-1 py-5' style={{marginTop: '10rem', minHeight: '50vh'}}>
            <div className='row d-flex justify-content-center'>
                <Accordion className='col-12 col-sm-9 col-md-6' flush>
                    {faqs.map((faq, index) => {
                        return(
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header className='primary-focus'><h4>{faq.question}</h4></Accordion.Header>
                                <Accordion.Body>
                                    {faq.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>
        </div>
        
    )

}

export default Questions;