import React from "react";
import { Link } from 'react-router-dom';
import './Banner.css'

import bannerImg from '../images/gamification/kingdomIllu.webp';

import { gsap } from "gsap";

class Banner extends React.Component {

    componentDidMount() {
        gsap.to('#play-game-btn', {opacity: 100, duration: 1}, '<+0.3');
    }

    constructor(props) {
        super(props)
    }

    
    
    render() {
        return(
            <div id='game-banner-container' className='container-fluid row d-flex flex-column justify-content-center align-items-center mx-0 px-0'>
                <div id='game-banner' className='px-0'>
                    <img src={bannerImg} className='d-block m-auto' style={{objectFit: "cover"}} />
                </div>
                <div id='game-banner-text' className='col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column align-items-center mb-5'>
                    <h1 className='title text-ussr'>The Boboverse</h1>
                    <h5 className='text-center'>The Boboverse is the Bobo of war metaverse, bridging the gap between traditional gaming and DeFi. Upgrade your Bobos, prove your worth in the Arena, earn and play with weapons and exclusive skins, and hibernate to earn $BOW Tokens, all in an immersive 3D environment.</h5>
                </div>
                <div className='row col-12 justify-content-center' style={{alignSelf: 'start'}}>
                    {/* <button className='btn play-game-btn selected-gradient col-3'><h1 className='text-ussr mb-0'>Enter the Realm</h1></button> */}
                    <a target="_blank" rel="noopener noreferrer" href="https://play.bobosofwar.com" id='play-game-btn' style={{opacity: 0}} className='col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 no-decoration selected-gradient text-center px-3 py-2 m-3'><h1 className='text-ussr mb-0'>Enter the Kingdom</h1></a>
                </div>
                <div className='row col-12 justify-content-center' style={{alignSelf: 'start'}}>
                    {/* <button className='btn play-game-btn selected-gradient col-3'><h1 className='text-ussr mb-0'>Enter the Realm</h1></button> */}
                    <a target="_blank" rel="noopener noreferrer" href="https://bobos-war.gitbook.io/bobos-of-war-b.o.w/bobos-of-war/introduction" id='play-game-btn' style={{opacity: 0}} className='col-8 col-sm-5 col-md-4 col-lg-3 col-xl-2 no-decoration selected-gradient text-center px-3 py-2 m-3'><h3 className='text-ussr text-white mb-0'>Read Whitepaper</h3></a>
                </div>
            </div>
            
        )
    }
}

export default Banner;