import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faToolbox} from "@fortawesome/free-solid-svg-icons"

import flagImg from './images/flags/transparent_white_flag.svg';

class MainNavbar extends React.Component {
    render() {
        return (
            <Navbar className='Navbar' variant='dark' expand="lg" sticky={this.props.currentPage !== '/lab' && this.props.currentPage !== '/series' ? 'top': ''}>
                <Container className='mx-3' style={{maxWidth: 'none'}}>
                    <Navbar.Brand id='navbar-title' href="/home"><img src={flagImg} alt='War Flag' style={{maxHeight: '100px'}} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto align-items-center">
                        <Nav.Link href="/home" className={`${this.props.currentPage === '/home' ? 'nav-active' : ''} text-ussr mr-2`}><FontAwesomeIcon icon={'home'} /> Home</Nav.Link>
                        <Nav.Link href="/gamification" className={`${this.props.currentPage === '/gamification' ? 'nav-active' : ''} text-ussr mr-2`}><FontAwesomeIcon icon={'gamepad'} /> Game</Nav.Link>
                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} className='text-ussr'><FontAwesomeIcon icon={'book'} /> Lore</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/series" className={`${this.props.currentPage === '/series' ? 'nav-active' : ''} text-ussr mr-2`}><FontAwesomeIcon icon={'film'} /> Animated Series</Dropdown.Item>
                                <Dropdown.Item href="/origins" className={`${this.props.currentPage === '/origins' ? 'nav-active' : ''} text-ussr mr-2`}><FontAwesomeIcon icon={'book-open'} /> Origins</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Nav.Link href="https://tools.bobosofwar.com" className={`text-ussr mr-2`}><FontAwesomeIcon icon={faToolbox} /> ToolBox</Nav.Link>
                        <div>
                            <a href={this.props.links.twitter} className='twitter-link float-right pb-2 pb-0 mx-1' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} className='twitter-logo bg-dark-0' style={{fontSize: '2.5em'}} /></a>
                            <a href={this.props.links.discord} className='discord-link float-right pb-2 pb-0 mx-1' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'discord']} className='discord-logo bg-dark-0' style={{fontSize: '2.5em'}} /></a>
                        </div>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}
export default MainNavbar;