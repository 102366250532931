import React, { useEffect } from "react";
import "./Roadmap.css";
import { ReactComponent as RoadmapSvg } from '../images/roadmap.svg'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Roadmap = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let roadmapTl = gsap.timeline({
            scrollTrigger: {
                trigger: ".timeline",
                start: "top 80%",
                end: "bottom 75%", 
                scrub: 1
            },
            defaults: {ease: "none", duration: 1}
        })

        let svgLines = gsap.utils.toArray(".line")
        let svgCircles = gsap.utils.toArray(".circle")
        let svgTexts = gsap.utils.toArray(".text")

        svgLines.forEach((line, index) => {
            roadmapTl.from(line, {drawSVG:"0"})
            let lineCircle = svgCircles[index]
            roadmapTl.fromTo(lineCircle, {drawSVG:"75% 75%", fillOpacity: 0}, {drawSVG:"125% 25%", fillOpacity: 1})
            let circleText = svgTexts[index]
            roadmapTl.from(circleText, {opacity: 0}, '<')
        })
    }, [])

  return (
    <div id='roadmap-banner' className='bg-dark-1'>
        <div className="timeline d-flex flex-column align-items-center justify-content-center">
            <RoadmapSvg width='100%' height='100%' className='header-shadow' />
        </div>
    </div>
  );
};


export default Roadmap;