import React, { useEffect } from "react";
import "./Presentation.css";
import { ReactComponent as FlagPoleSVG } from '../images/flags/flag_pole.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Presentation = (props) => {
    let {branches} = props
    let branchIds = ['#ground-force', '#air-force', '#marine-corp', '#covert-ops']

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(DrawSVGPlugin);

    useEffect(() => {
        gsap.to('#scroll-arrow', {scrollTrigger: {target: '#presentation', start: '60% top'}, opacity: 0, duration: 0.5})

        let poleTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#flag-pole",
                start: 'top 80%',
                end: 'bottom bottom',
                scrub: 1,
            },
            defaults: {ease: "none", duration: 0.7}
        })
        poleTl.from('#pole', {scale: '0'})
        poleTl.from('#flag', {opacity: 0, duration: 0.2})

        gsap.utils.toArray('.btn-branch').forEach((button, index) => {
            gsap.from(button, {scrollTrigger: {trigger: button, start: 'top 80%', end: 'bottom bottom'}, xPercent: 30 * (-1) ** (index+1), opacity: 0, ease: 'back'})
        })
    }, []);

    let goTo = (id) => {
        var elmntToView = document.querySelector(id);
        const y = elmntToView.getBoundingClientRect().top + window.pageYOffset - window.innerHeight*500/900;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    let scrollDown = () => {
        var elmntToView = document.querySelector('#presentation');
        const y = elmntToView.getBoundingClientRect().top + window.pageYOffset - window.innerHeight*250/900;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return(
        <div id='presentation' className='container-fluid position-relative d-flex row justify-content-center mx-0' style={{paddingTop: '10rem'}}>
            <div id='scroll-arrow' className='position-absolute mb-2 mx-auto' onClick={() => scrollDown()} style={{top: '2%'}}><FontAwesomeIcon icon="chevron-down" size='4x' /></div>
            <div id='flag-pole' className='col-0 col-lg-4 d-flex flex-column align-items-center justify-content-center position-relative'>
                <div id='container'>
                    <FlagPoleSVG width='100%' height='100%' />
                </div>
            </div>
            <div id='presentation-text' className='col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-ussr px-0 px-lg-5' style={{overflowX: 'hidden'}}>
                <h1 className='title header-shadow mt-5'>The Bobo War</h1>
                <h4 className='text-normal'>Join Dr. Pepenstein as he wages war against all that is evil, right here on the Solana blockchain. Own any of the randomly generated Bobos to become an adorable agent of pain and destruction! And to make the world a better place, of course...<br />Discover the 4 branches of the Bobo army: </h4>
                <div id='branches-refs' className='row d-flex justify-content-center mt-5 mb-5'>
                    {branches.map((branch, index) => {
                        return(
                            <button key={branch} className='btn-branch rounded primary-focus col-10 col-lg-5 m-3 py-2' onClick={() => goTo(branchIds[index])}><h2 style={{marginBottom: 0}}>{branch}</h2></button>
                        )
                    })}
                </div>
            </div>
        </div>
        
    )

}

export default Presentation;