import React, { useEffect } from "react";
import "./TeamBanner.css";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const TeamBanner = (props) => {
    let { teamInfo } = props

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let cardsTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#members-row",
                start: 'top 60%',
                end: 'bottom bottom',
                // markers: true,
            },
            defaults: {ease: "back"}})
        
        let teamCards = gsap.utils.toArray('.team-card')
        teamCards.forEach(card => {
            cardsTl.from(card, {yPercent: 15, opacity: 0}, '<+0.15')
        })
    }, [])

    return(
        <div id='team-banner' className='container-fluid' style={{paddingTop: '10rem'}}>
            <div id='members-row' className='row d-flex justify-content-center'>
                {teamInfo.map((teamMember) => {
                    return (
                        <div key={teamMember.name} className="team-card bg-dark-1 d-flex flex-column justify-content-end align-items-start rounded col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mx-3 mb-5 p-0">
                            <LazyLoadImage threshold={500} effect='opacity' src={teamMember.image} className="card-img-top rounded" alt="team member" />
                            <div className="card-body">
                                <h2 className="card-title header-shadow"><b>{teamMember['Name']}</b></h2>
                                <h4>{teamMember.function}</h4>
                                {Object.keys(teamMember.headers).map((header) => {
                                    return (
                                        <h6 key={header} className='card-sub-title mt-3'><b>{header}</b>: <span className='member-headers'>{teamMember.headers[header]}</span></h6>
                                    )
                                })}
                                <p className="card-text mt-5">{teamMember.body}</p>
                            </div>
                            {teamMember.twitter !== null ? <a href={teamMember.twitter} className='float-right align-self-end pb-2 mb-3 mr-3' target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'twitter']} size='3x' className='twitter-logo bg-dark-0' /></a> : null}
                        </div>
                    )
                })}
            </div>
        </div>
        
    )

}

export default TeamBanner;