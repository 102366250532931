import React, { useRef } from "react";
import './Weapons.css'


import M4A1Icon from "../images/gamification/weapons/icons/m4Icon.png"
import BBPulseIcon from "../images/gamification/weapons/icons/bb-pulseIconColored.png"
import BO80Icon from "../images/gamification/weapons/icons/BO-80IconColored.png"
import SO2SniperIcon from "../images/gamification/weapons/icons/SO2SniperIconColored.png"

import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Suspense } from "react";

import {splitText, addSecretTextAnimation} from '../utils.js';

import { gsap } from "gsap";

const rarityColors = ["#FFC300", "#FFA200", "#FF7B00"]
const weapons = [{id: 0, name: "M4A1", description: "Assault Rifle", rarity: 10, icon: M4A1Icon, position: [-1, 0, 0], scale: [0.4, 0.4, 0.4], rotation: [0, Math.PI * 2.25, 0]},
            {id: 1, name: "BB-Pulse", description: "Hand Gun", rarity: 45, icon: BBPulseIcon, position: [-1, 0, 0], scale: [0.15, 0.1, 0.15], rotation: [0, Math.PI * 1.75, 0]},
            {id: 2, name: "BO-80", description: "Heavy Assault Rifle", rarity: 70, icon: BO80Icon, position: [-1, 0, 0], scale: [0.2, 0.2, 0.2], rotation: [0, Math.PI * 1.8, 0]},
            {id: 3, name: "S02_Sniper", description: "Heavy Sniper", rarity: 90, icon: SO2SniperIcon, position: [-2, 0, -1], scale: [0.1, 0.1, 0.1], rotation: [0, Math.PI * 1.75, 0]}]


const WeaponMesh = (props) => {
    const groupMesh = useLoader(FBXLoader, `content/3D/weapons/${weapons[props.weaponId].name}/Mesh/mesh.fbx`)
    const [albedoMap, metalnessMap, normalMap] = useLoader(TextureLoader, [
        `content/3D/weapons/${weapons[props.weaponId].name}/Textures/albedo.webp`,
        `content/3D/weapons/${weapons[props.weaponId].name}/Textures/metalness.webp`,
        `content/3D/weapons/${weapons[props.weaponId].name}/Textures/normal.webp`,
    ])

    const scale = weapons[props.weaponId].scale
    const rotation = weapons[props.weaponId].rotation
    const posititon = weapons[props.weaponId].position

    // const gunMesh = useRef();
    // const meshRef01 = useRef(), meshRef02 = useRef(), meshRef03 = useRef(), meshRef04 = useRef();
    // const availableMeshes = [meshRef01, meshRef02, meshRef03, meshRef04]
    // const meshes = [gunMesh, meshRef01, meshRef02, meshRef03, meshRef04]

    // useFrame(state => {
    //     // const time = state.clock.getElapsedTime();
    //     meshes.map(mesh => {
    //         // mesh.current.rotation.z = Math.PI / 4 -scroll.offset * 2
    //         // mesh.current.rotation.z = Math.PI / 2 - (props.mousePos.x - props.mousePos.x/2) * 0.001
    //         // mesh.current.rotation.y = -(props.mousePos.y*5 - props.mousePos.y/2) * 0.001
    //         try {
    //             mesh.current.rotation.y = rotation[1] + (props.mousePos.x - props.mousePos.x/2) * 0.001 *2
    //             mesh.current.rotation.x = mesh.current.rotation.z = - (props.mousePos.y - props.mousePos.y/2) * 0.0025 * 2
    //         } catch {}
            
    //         // mesh.current.rotation.x = -(props.mousePos.x - props.mousePos.x/2) * 0.001
    //     })
    // })

    const mainMesh = groupMesh.children[0]
    let availableChildren = Object.fromEntries(
        mainMesh.children.map(child => [child.name, child])
    )
      
    return <group>
        <mesh geometry={mainMesh.geometry} position={posititon} scale={scale} rotation={rotation} {...props}>
            <meshStandardMaterial map={albedoMap} normalMap={normalMap} metalnessMap={metalnessMap} />
        </mesh>
        {Object.keys(availableChildren).map((key, index) => {
            let mesh = <mesh key={index} geometry={availableChildren[key].geometry} position={posititon} scale={scale} rotation={rotation} {...props}>
                    <meshStandardMaterial map={albedoMap} normalMap={normalMap} metalnessMap={metalnessMap} />
                </mesh>
            return mesh
        })}
    </group>
}



class Weapons extends React.Component {
    componentDidMount() {
        if(window.innerWidth > 992) {
            this.canvas3D.current.addEventListener("mousemove", this.getMousePosition);
        }
        this.selectWeapon(null, 0)

        // Entrance anim
        let entranceTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#weapons-container",
                start: 'top 60%',
            }
        })
        addSecretTextAnimation(entranceTl, '#weapons-info h1 span', 0.15, "", '<0.06')
        entranceTl.from("#weapons-info h5", {xPercent: -10, opacity: 0, duration: .3, ease: "back"}, ">")
        entranceTl.from("#weapons-canvas", {opacity: 0, duration: 3}, '<')
        entranceTl.from("#weapon-details", {xPercent: 10, opacity: 0, duration: .5, ease: "back"}, '>-2')

        gsap.utils.toArray("#weapon-picker button").map(button => {
            entranceTl.from(button, {yPercent: 100, opacity: 0, duration: .5, ease: "back"}, '<0.2')
        })
        
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedWeaponId: 0,
            mousePos: {x: 0, y: 0}
        }
        this.canvas3D = React.createRef()
        this.getMousePosition = this.getMousePosition.bind(this)
        this.selectWeapon = this.selectWeapon.bind(this)
    }

    getMousePosition(e) {
        this.setState({mousePos: {x: e.clientX, y: e.clientY}})
    }

    selectWeapon(event, id) {
        this.setState({selectedWeaponId: id}, function() {
            let selectedWeapon = weapons[id]
            let progressBarColor = selectedWeapon.rarity <= 30 ? rarityColors[0] : selectedWeapon.rarity <= 60 ? rarityColors[1] : rarityColors[2]

            let weaponDetailsTl = gsap.timeline()
            addSecretTextAnimation(weaponDetailsTl, '#weapon-name-text span', 0.15, "", '<0.04')
            weaponDetailsTl.to("#weapon-rarity-container .rarity-progress", {width: selectedWeapon.rarity.toString() + '%', backgroundColor: progressBarColor, duration: 1}, '<')
            weaponDetailsTl.from("#weapon-description-text", {opacity: 0, duration: 0.5}, '<0.1')
        })
    }


    render() {
        let weaponRarityBar = <div id="weapon-rarity-container" style={{width: "100%", height: "20px", background: "#666666", borderRadius: "2rem"}}>
            <div className='position-absolute rarity-progress' style={{height: "inherit", borderRadius: "inherit"}}></div>
        </div>
        return(
            <div id='weapons-container' ref={this.canvas3D} className='container-fluid row d-flex justify-content-center mx-0 px-0 pt-5'>
                <Canvas id='weapons-canvas' linear flat>
                    <Suspense fallback={null}>
                        <spotLight position={[-15, 10, 10]} angle={-Math.PI/3} intensity={.6} />
                            <WeaponMesh mousePos={this.state.mousePos} weaponId={this.state.selectedWeaponId} />
                    </Suspense>
                </Canvas>
                <div id='weapons-overlay' className='d-flex flex-column container-fluid row justify-content-end align-items-center pt-3 pl-3'>
                    <div id='weapons-info' className='row mb-auto px-0 px-md-2'>
                        <h1 className='col-12 sub-title text-ussr px-0 px-md-2'>{splitText("Weapons", "text-secret-hide")}</h1>
                        <h5 className='col-12 col-lg-6 ml-md-4'>Weapons are 3D NFTs that can be stylized with skins and used by your Bobo in the arena. <br />Each weapon possesses different accessories such as barrel, optic, stock, ammunition, etc... The level of every accessory can be upgraded at the weaponsmith, making the weapon even stronger.</h5>
                    </div>
                    <div id="weapon-details" className='d-flex flex-column col-0 col-md-3 col-lg-2 align-self-end mr-5 mb' style={{marginBottom: "7rem"}}>
                        <h5><b className='text-ussr'>Name:</b> <span id='weapon-name-text'>{splitText(weapons[this.state.selectedWeaponId].name, 'text-secret-hide')}</span></h5>
                        <div className='ml-2'>
                            <h6><b className='text-ussr'>Type:</b> <span id='weapon-description-text'>{weapons[this.state.selectedWeaponId].description}</span></h6>
                            <h6><b className='text-ussr'>Rarity:</b></h6>
                            {weaponRarityBar}
                        </div>
                    </div>
                    <div id='weapon-picker' className='d-flex row justify-content-center mb-3' style={{width: "100%", height: "20%"}}>
                        <div className='d-flex col-12 col-sm-11 col-md-9 col-lg-6 row'>
                            {weapons.map(weapon => {
                                    let selectedWeapon = this.state.selectedWeaponId === weapon.id
                                    return <button key={weapon.id} onClick={(event) => this.selectWeapon(event, weapon.id)} disabled={selectedWeapon} className={`btn col mx-2 ${selectedWeapon ? "selected": ""}`}><img src={weapons[weapon.id].icon} style={{width: "100%", height: 'auto'}} /></button>
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Weapons;