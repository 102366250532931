import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Home.js'
import GameInfo from './GameInfo.js'
import AnimatedSeries from './AnimatedSeries3.js'
import Origins from './Origins.js'
// import Minter from './Minter.tsx'
import ConstructionMinter from './ConstructionMinter.js'

class Main extends React.Component {
    render() {
        return(
            <div>
                <Switch>
                    <Redirect exact from='/' to='/home' />
                    <Route exact path='/home' render={() => <Home links={this.props.links} />}></Route>
                    <Route exact path='/gamification' render={() => <GameInfo links={this.props.links} />}></Route>
                    <Route exact path='/series' render={() => <AnimatedSeries links={this.props.links} series={this.props.series} />}></Route>
                    <Route exact path='/origins' render={() => <Origins links={this.props.links} />}></Route>
                    {/* <Route exact path='/lab' render={() => <Minter
                        candyMachineId={this.props.candyMachineId}
                        config={this.props.config}
                        connection={this.props.connection}
                        startDate={this.props.startDateSeed}
                        treasury={this.props.treasury}
                        txTimeout={this.props.txTimeout}
                        />}>
                    </Route> */}
                    <Route exact path='/lab' render={() => <ConstructionMinter />}></Route>
                </Switch>
            </div>
        )
    }
}

export default Main;