import { gsap } from "gsap";

export function splitText(text, className) {
    let charList = text.split('').map((char, index) => {
        return(
            <span key={index} className={className}>{char}</span>
        )
    })
    return charList
}

export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function addSecretTextAnimation(timeline, target, delay, additionalClass, offset) {
    let arenaShuffledCharacters = shuffleArray(gsap.utils.toArray(target))
    arenaShuffledCharacters.forEach((char, index) => {
        var tl = new gsap.timeline();
        tl.set(char, { className: "text-secret-hide state-1 " + additionalClass })
            .set(char, { delay: delay, className: "text-secret-hide state-2 " + additionalClass })
            .set(char, { delay: delay, className: "text-secret-hide state-3 " + additionalClass })

            if(index === 0) {
                timeline.add(tl, '>');
                return null
            }
            timeline.add(tl, offset);
    })
}



export function getBSsize(width) {
    return width >= 1400 ? 'xxl': width >= 1200 ? 'xl': width >= 992 ? "lg": width >= 768 ? "md": width >= 576 ? "sm": "xs"
}