import React, { useEffect } from "react";
import "./MutantsBanner.css";
import mutantImg from '../images/bobos/mutant.webp'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const MutantsBanner = (props) => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let containerTl = gsap.timeline({
            scrollTrigger: {
                trigger: '#mutants-banner',
                start: 'top center',
                end: 'center center',
                // markers: true
            },
            defaults: {ease: 'back', duration: 1}
        })

        containerTl.from('#mutant-img', {opacity: 0})
        containerTl.to('#mutant-description h1 span', {duration: 2, text: 'Mutants'}, '<')
        containerTl.from('#mutant-description p', {xPercent: 20, opacity: 0, duration: 0.5}, '<+0.1')
        containerTl.from('#mutant-description .mutant-small-img', {opacity: 0}, '<+0.3')
    }, []);

    return(
        <div id='mutants-banner' className='container-fluid d-flex justify-content-center bg-dark-1' style={{paddingTop: '10rem', paddingBottom: '10rem'}}>
            <hr />
            <div className='row col-10 col-xl-8 d-flex justify-content-center'>
                <img alt='mutant chief' id='mutant-img' src={mutantImg} className='col-0 col-lg-5 col-xl-4 img-fluid my-auto' />
                <div id='mutant-description' className='branch-container d-flex flex-column col-10 col-lg-7 col-xl-6 offset-xl-1'>
                    <h1 className='sub-title header-shadow text-ussr'><span className='branch-name float-left'></span></h1>
                    <p className='text-normal'>Like humans (and bobos), science can sometimes be flawed. During the cloning process, we have observed that 10-15% of bobos will spontaneously react to Dr. Pepenstein’s unique DNA, resulting in a Mutant Bobo. <br /><br />All mutants have a singular special ability, making them the rarest and most powerful bobos of all. Their unmatched strength and unique appearance make them incredibly easy to track, however, and as such no Stealth mutants have been recorded to date.</p>
                    <img className='mutant-small-img d-block d-md-block d-lg-none' src={mutantImg} alt='mutant-leader' />
                </div>

            </div>
        </div>
        
    )

}

export default MutantsBanner;