import React from "react";
import './Roadmap.css';

// import {ReactComponent as RoadmapSVG} from "./roadmap.svg";

import {ReactComponent as RoadmapLongSVG} from "./roadmapLong.svg";
import {ReactComponent as RoadmapShortSVG} from "./roadmapShort.svg";

import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";



class Roadmap extends React.Component {

    componentDidMount() {
        let phase1Tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#phase-container-1",
                start: "top 80%",
                end: "bottom 75%", 
                scrub: 1
            },
            defaults: {ease: "none", duration: 1}
        })

        let longRoadmapCircles = gsap.utils.toArray("#roadmap-long .roadmap-circle")

        phase1Tl.fromTo("#roadmap-long .line", {drawSVG:"0%"}, {drawSVG:"100%", duration: 5})
        // Step 1
        phase1Tl.to(longRoadmapCircles[2], {opacity: 1, duration: 0.2}, "<+20%")
        phase1Tl.from("#step-1", {xPercent: -20, opacity: 0, duration: 0.5}, "<+20%")
        // Step 2
        phase1Tl.to(longRoadmapCircles[1], {opacity: 1, duration: 0.2}, ">+200%")
        phase1Tl.from("#step-2", {xPercent: 20, opacity: 0, duration: 0.5}, "<+20%")
        // Step 3
        phase1Tl.to(longRoadmapCircles[0], {opacity: 1, duration: 0.2}, ">+200%")
        phase1Tl.from("#step-3", {xPercent: -20, opacity: 0, duration: 0.5}, "<+20%")

        // Phase 2
        let phase2Tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#phase-container-2",
                start: "top 80%",
                end: "bottom 75%", 
                scrub: 1
            },
            defaults: {ease: "none", duration: 1}
        })

        let shortRoadmapCircles = gsap.utils.toArray("#roadmap-short .roadmap-circle")
        console.log(shortRoadmapCircles)

        phase2Tl.fromTo("#roadmap-short .line", {drawSVG:"0%"}, {drawSVG:"100%", duration: 5})
        // Step 4
        phase2Tl.to(shortRoadmapCircles[1], {opacity: 1}, "<+20%")
        phase2Tl.from("#step-4", {xPercent: 20, opacity: 0}, "<+20%")
        // Step 5
        phase2Tl.to(shortRoadmapCircles[0], {opacity: 1}, "<150%")
        phase2Tl.from("#step-5", {xPercent: -20, opacity: 0}, "<+20%")
    }

    constructor(props) {
        super(props);
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(DrawSVGPlugin);
    }

    render() {
        return(
            <div id='roadmap-container' className="container-fluid d-flex flex-column justify-content-start align-items-center">
                <div id='phase-container-1' className='phase-container d-flex flex-column justify-content-start align-items-center mb-5'>
                    <h1 className='text-ussr title mb-5'>PHASE 1</h1>
                    <div className='d-flex steps flex-column justify-content-stretch align-items-center mt-4'>
                        <RoadmapLongSVG width="100%"  className='d-none d-md-flex' />
                        <div className='steps-container d-flex flex-column justify-content-between align-items-center'>
                            <div id='step-1' className='row roadmap-step-row mb-3 mb-md-0'>
                                <div className='step-container col-11 col-md-5 offset-lg-1 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>Bobo NFT</h1>
                                    <h5 className='ml-3 mb-4 text-normal'>18th January 2022</h5>
                                    <ul>
                                        <li><b>Bobos of War Classic (PNG)</b> - Army of 8450 highly trained Bobos, functioning as the gateway to the Bobo Kingdom.</li>
                                        <li><b style={{color: "#aa7700"}}>Bobos of War Genesis (GIF)</b> - 50 Rare animated BoW NFTs will be available to mint, adding a multiplier bonus for all XP and $BOW tokens, and an exclusive 3D playable Bobo for Arena battles.</li>
                                        <li><b>Bobos of War PoW</b> - Secret mission to save the 388 Bobo Prisoners of War (BoW PoW), including 38 Genesis. The community will have various ways in which they will be able to rescue (and get!) these Bobo prisoners (giveaways, competitions, rescue missions, raffles…).</li>
                                    </ul>
                                </div>
                            </div>
                            <div id='step-2' className='row roadmap-step-row mb-3 mb-md-0'>
                                <div className='step-container offset-1 col-11 offset-md-7 col-md-5 offset-lg-7 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>Game Environments</h1>
                                    <h5 className='ml-3 mb-4 text-normal'></h5>
                                    <h6>The very first training camp mini-game as well as the 3D environments of the game will be available for Bobo owners to go play and explore.</h6>
                                    <ul>
                                        <li>3D Capital cities and Hibernation station will be available to visit</li>
                                        <li>Bobo Kingdom map release</li>
                                        <li>Launch Hibernation Station with various staking options</li>
                                        <li>Bobo Houses demo with placeable furniture</li>
                                        <li>3D Bobo Bank, Governmental Palace, Common Hall, Casino hotel and Bobo homes will be available to visit</li>
                                        <li>First Training Camp for Ground Forces – Alpha Testing</li>
                                        <li>Bobo casino opens with one game having $BOW, skins and weapons on the line</li>
                                        <li>Launch of Government Palace, home of the Bobo Kingdom DAO</li>
                                    </ul>
                                </div>
                            </div>
                            <div id='step-3' className='row roadmap-step-row mb-3 mb-md-0'>
                                <div className='step-container col-11 col-md-5 offset-lg-1 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>Game</h1>
                                    <h5 className='ml-3 mb-4 text-normal'></h5>
                                    <h6>Expansion on the other branch’s training camp minigames and alpha launch of the arena matches.</h6>
                                    <ul>
                                        <li>Training Camp for Air Force #2 (Bobo jet)</li>
                                        <li>Training Camp for Navy #3 (Marine Destroyer)</li>
                                        <li>Training Camp for Covert Ops #4 (Classified)</li>
                                        <li>Launch of <b>desktop game app</b></li>
                                        <li><b style={{color: "#aa7700"}}>Launch of Arenas Alpha</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='phase-container-2' className='phase-container d-flex flex-column justify-content-start align-items-center' style={{marginTop: "2rem"}}>
                    <h1 className='text-ussr title my-5'>PHASE 2</h1>
                    <div className='d-flex steps flex-column align-items-center mt-4'>
                        <RoadmapShortSVG width="100%"  className='d-none d-md-flex' />
                        <div className='steps-container d-flex flex-column justify-content-between align-items-center'>
                            <div id='step-4' className='row roadmap-step-row mb-3 mb-md-0'>
                                <div className='step-container offset-1 col-11 offset-md-7 col-md-5 offset-lg-7 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>United Bobo Kingdom</h1>
                                    <h5 className='ml-3 mb-4 text-normal'></h5>
                                    <h6>Integration of the $BOW token around the already existing gameplay but with utility taking a core place in the player’s progress.</h6>
                                    <ul>
                                        <li>$BOW Single Asset Staking - BoboBank in 3D</li>
                                        <li>$BOW DEX listing and magic eden in game token integration</li>
                                        <li><b style={{color: "#aa7700"}}>Bobo in-game Marketplace via Magic Eden</b> - Bobo Silk Road launched, using the in-game $BOW token as a currency.</li>
                                    </ul>
                                </div>
                            </div>
                            <div id='step-5' className='row roadmap-step-row mb-3 mb-md-0'>
                                <div className='step-container col-11 col-md-5 offset-lg-1 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>Weapons</h1>
                                    <h5 className='ml-3 mb-4 text-normal'></h5>
                                    <h6>Deployment of weapon NFTs that can be used within the arena and are composed of two elements: upgradeable equipment and cosmetic skins.</h6>
                                    <ul>
                                        <li>First airdrop of 3D weapons and sales of weapons via in store shop BSR</li>
                                        <li>Launch of weapon’s <b style={{color: "#aa7700"}}>equipment upgrade at the weaponsmith</b> (barrel, scope, stock, magazine..)</li>
                                        <li>Introduction of Skins, cosmetic NFTs for your weapons</li>
                                        <li>Skins are 2D texture NFTs that can be applied to any weapon NFT to change its appearance. Skins can be bought and sold on the silk road marketplace with $BOW tokens. A variety of skins of varying rarities exist, with some being 1/1s, and others being dropped on the marketplace for mint.</li>
                                        <li>Quests to receive $BOW tokens, weapons and unique skins</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div id='step-6' className='row roadmap-step-row'>
                                <div className='step-container offset-1 col-11 offset-md-7 col-md-5 offset-lg-7 col-lg-4'>
                                    <h1 className='text-ussr sub-title'>Camp Demo</h1>
                                    <h5 className='ml-3 mb-4 text-normal'>January 2022</h5>
                                    <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In orci neque, cursus sed sagittis fringilla, commodo sed lacus. Phasellus interdum dui ligula, in consectetur ex tincidunt iaculis.</h6>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                        <li>Lorem ipsum dolor sit amet</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Roadmap;