import React from "react";
import { Link } from 'react-router-dom';

import "./LabBanner.css";
// import LabImg from '../images/labImage.jpg'
import sketchLab from '../images/sketchLab.webp'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const LabBanner = () => {
    gsap.registerPlugin(ScrollTrigger);

    // useEffect(() => {
    //     if(window.innerWidth > 992) {
    //         let labSlideTl = gsap.timeline({
    //             scrollTrigger: {
    //                 trigger: "#lab-banner",
    //                 start: "80% 80%",
    //                 end: () => "+=" + (document.querySelector('#lab-banner').offsetWidth), 
    //                 scrub: true,
    //                 pin: true,
    //                 anticipatePin: 1,
    //                 // markers: true
    //             },
    //             defaults: {ease: "none"}
    //         })
    
    //         labSlideTl.fromTo(".color-img", { xPercent: 100, x: 0}, {xPercent: 0})
    //         .fromTo(".color-img img", {xPercent: -100, x: 0}, {xPercent: 0}, 0);
    //     }
    // });

    return(
        <section id='lab-banner' className='container-fluid row d-flex align-items-center justify-content-center px-0 mx-0' style={{height: '80vh', marginTop: '5rem', marginBottom: '5rem'}}>
            {/* {window.innerWidth > 992 ?  */}
            {/* {false === true ? */}
            {/* <div className='col-12 col-lg-10 lab-images-section' style={{height: '90%'}}>
                <div className='comparison-image bw-img'>
                    <img src={sketchLab} alt='before' />
                </div>
                <div className='comparison-image color-img'>
                    <img src={LabImg} alt='after' />
                </div> 
                <Link to='/lab' id='lab-anchor' className='badge-pill badge-dark position-absolute no-decoration px-3 py-2 m-3'><h2 className='text-ussr mb-0' style={{letterSpacing: '0.1em'}}><b>Visit the lab</b></h2></Link>
            </div> : */}
            <div className='col-12 col-lg-10 px-0' style={{height: '90%'}}>
                <div className='d-flex justify-content-center' style={{height: '100%', overflowX: 'hidden'}}>
                    <img src={sketchLab} alt='lab' className='no-comp-img' />
                </div>
                <Link to='/lab' id='lab-anchor' className='badge-pill badge-dark position-absolute no-decoration selected-gradient px-3 py-2 m-3'><h2 className='text-ussr mb-0' style={{letterSpacing: '0.1em'}}><b>Visit the lab</b></h2></Link>
            </div>
        </section>
    )
}

export default LabBanner;