import React from 'react'
import { Link } from 'react-router-dom';
import './Home.css'

import bannerImg from './images/banner2.webp'
import solLogo from './images/solanaLogo.webp'

import Presentation from './HomePageComponents/Presentation.js'
import BranchesGroup from './HomePageComponents/BranchesGroup.js'
import MutantsBanner from './HomePageComponents/MutantsBanner.js'
import AnimationsPresentation from './HomePageComponents/AnimationsPresentation.js'
import LabBanner from './HomePageComponents/LabBanner.js'
import Roadmap from './HomePageComponents/Roadmap.js'
import TeamBanner from './HomePageComponents/TeamBanner.js'
import Questions from './HomePageComponents/Questions.js'
import MainFooter from './MainFooter.js';

import boboAliWood from './images/bobos/AliWood.webp';
import boboRichard from './images/bobos/Richard.webp';
import boboBenjamin from './images/bobos/Benjamin.webp';
import boboEtienne from './images/bobos/Etienne.webp';
import boboLucien from './images/bobos/Lucien.webp';
import boboElisa from './images/bobos/Elisa.webp';
import boboDavid from './images/bobos/david.webp';
import boboLeoArt from './images/bobos/LeoArt.webp';
import boboLandLord from './images/bobos/landlord.webp';
import boboKwengo from './images/bobos/kwengo.webp';
import boboEnzo from './images/bobos/modranger.webp';

// import meLogo from "./images/meLogo.svg"
import {ReactComponent as MeLogo} from "./images/meLogo.svg"


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class Home extends React.Component {
    componentDidMount() {
        gsap.to('#scroll-arrow', {scrollTrigger: {target: '.photobanner', start: '2% top'}, opacity: 0, duration: 0.5})
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#welcome-banner",
              start: "top top",
              end: "bottom top",
              scrub: true
            }
          });
        tl.to('#banner-img', {y: -document.querySelector('#banner-img').offsetHeight/8, ease: "none"}, 0)        
    }

    constructor(props) {
        super(props)
        this.branches = ['Ground Force', 'Air Force', 'Marines', 'Covert Ops'];
        gsap.registerPlugin(ScrollTrigger);
    }

    render() {
        return(
            <div id='home-page'>
                {/* <div id='scroll-arrow' className='position-absolute mb-2 mx-auto' onClick={() => this.scrollDown()}><FontAwesomeIcon icon="chevron-down" size='4x' /></div> */}
                <div id='welcome-banner' className='container-fluid row d-flex align-items-center justify-content-end px-0 mx-0'>
                    <div className="photobanner d-flex justify-content-center" style={{width: '100%', height: '100%'}}>
                        <img id='banner-img' src={bannerImg} className='d-block m-auto' alt="home banner" style={{height: '110%'}}/>
                    </div>
                    
                    <div className='row col-12 col-md-9 col-xl-5 d-flex flex-column align-items-end mt-5 mb-3 mb-md-5 mt-5 mx-3 px-2' style={{zIndex: 10}}>
                        <h1 id='banner-title' className='title title-shadow col-12 mt-4 text-ussr text-wrap text-right'>Bobos Of War</h1>
                        <h3 id='banner-body' className='text-right col-12'>Launch yourself through space and time, deep into the metaverse where the fate of the free world rests in the paws of the world's most cuddly army.</h3>
                        <a href='https://tools.bobosofwar.com' id='mint-anchor' className='badge-pill badge-dark no-decoration selected-gradient px-3 py-2 m-3'><h2 className='text-ussr mb-0' style={{letterSpacing: '0.1em'}}><b>Toolbox</b></h2></a>
                        <a href="https://magiceden.io/marketplace/bobos_of_war" target="_blank" rel="noopener noreferrer" className="badge-pill badge-dark selected-gradient" style={{width: "fit-content"}}><MeLogo className="mx-3" /></a>
                    </div>
                    <p id='sol-animated-text' className='col-9 mt-auto' style={{textAlign: 'right'}}><b>First Animated Series NFT Collection with GameFi integration on </b><img src={solLogo} alt='solana logo' /></p>
                </div>
                <Presentation branches={this.branches} />
                <BranchesGroup branches={this.branches}/>
                <MutantsBanner />
                <AnimationsPresentation />
                <LabBanner />
                <Roadmap />
                <TeamBanner teamInfo={[
                    {'Name': 'ModRanger', function: 'Co-Founder', headers: {'Designations': 'Founding Father; Original BAMF', 'Known Aliases': 'MC MoneyHandz', 'Base Of Operations': 'Washington D.C.', 'Areas of Expertise': 'Jack of All Trades, Master of Some'}, body: <p>Former/future millionaire. Said to be able to solve any problem for the right price. 
                    <br /><br />
                    Excellent at spotting talent - even better at finding ways to capitalize on it.
                    <br /><br />
                    Recruited the original members of the BAMFs (Bobo Agency of Military Force).
                    <br /><br />
                    <b>WANTED ALIVE.</b>
                    </p>, image: boboEnzo, twitter: 'https://twitter.com/mod_ranger'},
                    {'Name': 'Kwengo', function: 'Co-Founder & Dev', headers: {'Designations': 'Developer; Original BAMF', 'Known Aliases': 'The Taskmaster', 'Base Of Operations': 'France', 'Areas of Expertise': 'Blockchain, military martial arts, cocktail pairings'}, body: <p>Programming prodigy with 5+ years experience. Specializes in Python, JavaScript, Solidity and Rust. 
                    <br /><br />
                    In addition to being an OG BAMF, The Taskmaster is the founder and chairman of BRAIN (Bobo Rights and Amnesty Intelligence Network).
                    <br /><br />
                    Report sighting to your superior. 
                    <br /><br />
                    <b>DO NOT ENGAGE.</b>            
                    </p>, image: boboKwengo, twitter: 'https://twitter.com/CryptoKwengo'},
                    {'Name': 'Landlord', function: 'Co-Founder', headers: {'Designations': 'Financier; Original BAMF', 'Known Aliases': 'CLASSIFIED', 'Base Of Operations': 'UK', 'Areas of Expertise': 'Finance, Wealth Management, Equities Market, Talent Scouting'}, body: <p>Finance wiz by day, NFT builder by night. May or may not be single-handedly responsible for the Berlin Blackout in February 2019.
                    <br /><br />
                    Can unite any team or community under a single cause; rumored to have a charming and persuasive nature that “[...] borders on the supernatural” (Laveaux, 2018).
                    <br /><br />
                    <b>KILL ON SIGHT.</b>           
                    </p>, image: boboLandLord, twitter: 'https://twitter.com/LandLordETH'},

                    {'Name': 'Elisa', function: 'Artist', headers: {'Alias': 'The Toymaker', 'Years Active': '2004 - Present'}, body: <p>Designer. Armorer. Weapons expert. Groomer.
                    <br /><br />
                    Responsible for giving bobos their adorable and/or fearsome appearance, helping them look less (or in some cases, more) like real warriors.
                    <br /><br />
                    Manufactured and designed every weapon and article of clothing for the entire bobo army, earning their nickname as “The Toymaker.”                                                
                    </p>, image: boboElisa, twitter: null},
                    {'Name': 'AliWood Studios', function: 'Animators & VFX Artists', headers: {'Alias': 'The Puppeteers', 'Years Active': '2009 - Present'}, body: <p>Animation and re-animation experts. Recruited by Landlord to serve as Dr. Pepenstein’s assistant.  
                    <br /><br />
                    Before the addition of Wood’s technologies and expertise, bobo clones lacked mobility and finger dexterity, and overall appeared to be “[...] super depressed, with dead, soulless eyes” (ModRanger, September 30, 2021).                                                    
                    </p>, image: boboAliWood, twitter: null},
                    {'Name': 'David', function: 'Art Director', headers: {'Alias': 'David', 'Years Active': '2000 - Present'}, body: <p>Creator. Concept artist. Overseer.
                    <br /><br />
                    Responsible for giving life to the bobo storyline and making their mark in history. Assisted on the earliest designs and framework of the original bobo army clones.
                    <br /><br />
                    Believed to have exclusive access to top secret BAMF files.                 
                    </p>, image: boboDavid, twitter: null},
                    {"Name": "Lucien", function: "Artist & Animator", headers: {}, body: "", image: boboLucien, twitter: null},
                    {"Name": "Etienne", function: "Game Artist", headers: {}, body: "", image: boboEtienne, twitter: null},
                    {"Name": "Benjamin", function: "Artist & Animator", headers: {}, body: "", image: boboBenjamin, twitter: null},
                    {"Name": "Richard", function: "Gaming Advisor", headers: {}, body: "", image: boboRichard, twitter: null},
                    {"Name": "LeoArt Studios", function: "Animators & VFX Artists", headers: {}, body: "", image: boboLeoArt, twitter: null},
                ]} />
                <Questions />
                <MainFooter links={this.props.links} />
            </div>
        )
    }
}

export default Home