import React from 'react'
import "./TokenContainer.css"

import tokenRender from "../images/gamification/token.webp"
import bankRender from "../images/gamification/bankExt.webp"

class TokenContainer extends React.Component {
    render() {
        return(
            <div id='token-container' className='container-fluid d-flex position-relative'>
                <div id='bank-image' className='d-flex'>
                    <img src={bankRender} style={{opacity: 0.6}} />
                    <div className='d-flex flex-column position-absolute row align-items-end'>
                        <h1 className='title text-ussr'>Bobo Bank</h1>
                        <p className='col-11 col-md-8 col-lg-7'>The bank is a paradise for Bobo traders, investors and those interested in the DeFi aspect of Bobos of War.<br /> The one-stop-fits-all for all $BOW related services (buying and selling tokens, single asset staking, LP), all in a fully interactive 3D building.</p>
                    </div>
                </div>
                <div id='token-image' className='d-flex'>
                    <img src={tokenRender} />
                    <div className='d-flex flex-column position-absolute row'>
                        <h1 className='title text-ussr'>UNITED BOBO KINGDOM</h1>
                        <p className='col-11 col-md-8 col-lg-7'>Our <b>utility</b> in game currency used to buy weapons, skins and Bobos on our marketplace.<br /> $BOW can be earned in arena P2E games or bought on a DEX, and is utilized to buy NFTs on the BSR (in-game marketplace), upgrade your weapons, enter fee arena matches, play in the casino...</p>
                    </div>
                    <h6 className="position-absolute mr-2 text-ussr" style={{bottom: 0, right: 0}}>1 $BOW = 1 $BOW</h6>
                </div>
            </div>
        )
    }
}

export default TokenContainer;