import React, { useEffect } from "react";
import "./BranchesGroup.css";
import { ReactComponent as BobosGroupSvg } from '../images/bobos-group.svg'
import boboGFLeader from '../images/bobos/ground_force_leader.webp'
import boboAFLeader from '../images/bobos/air_force_leader.webp'
import boboMLeader from '../images/bobos/marines_leader.webp'
import boboCOLeader from '../images/bobos/covert_ops_leader.webp'

import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";


const BranchesGroup = (props) => {
    let images = [boboGFLeader, boboAFLeader, boboMLeader, boboCOLeader]
    let { branches } = props
    
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(DrawSVGPlugin);
    gsap.registerPlugin(TextPlugin);

    useEffect(() => {
        gsap.utils.toArray(".group-img").forEach(img => {
            gsap.from(img, {scrollTrigger: {trigger: img, start: 'top bottom', end: 'bottom center', scrub: true}, opacity: 0, ease: 'in-out'})
        })

        let pathTl = gsap.timeline({
                scrollTrigger: {
                    trigger: "#line",
                    start: 'top center',
                    end: 'bottom center',
                    scrub: 0.5,
                    // markers: true,
                },
                defaults: {ease: "ease-out"}})
        pathTl.fromTo('#line', {drawSVG:"100% 100%"}, {drawSVG:"0% 100%", duration: 1.5})

        let branchesHeaders = gsap.utils.toArray(".branch-header")
        let branchesBodies = gsap.utils.toArray(".branch-body")
        let branchSmallImgs = gsap.utils.toArray(".branch-small-img")
        gsap.utils.toArray(".branch-name").forEach((text, index) => {
            let textTl = gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top 70%',
                    end: 'bottom 70%',
                    // markers: true,
                },
                defaults: {ease: "back"}
            })
            let branchName = branches[index]
            let branchNameTl = gsap.timeline()
            branchNameTl.to(text, {duration: 0.8, text: branchName})
            textTl.add(branchNameTl)
            textTl.from(branchesHeaders[index], {xPercent: 5, opacity: 0}, '<')
            textTl.from(branchesBodies[index], {xPercent: 5, opacity: 0}, '<+0.1')
            textTl.from(branchSmallImgs[index], {opacity: 0}, '<+0.3')
        })

    });

    return(
        <div id='branches-group' className='d-flex bg-dark-1' style={{paddingTop: '7rem'}}>
            <div id='bobos-group' className='d-flex flex-column align-items-start justify-content-start position-relative'>
                <div id='container' style={{height: '100%', width: '100%'}}>
                    <BobosGroupSvg width='100%' height='100%' />
                    {images.map((img, index) => <img key={index} src={img} alt='branch-chief' className={`group-img img-${index} position-absolute mr-3`} />)}
                </div>
            </div>
            <div id='military-branches' className='d-flex flex-column align-items-center justify-content-around text-ussr'>
                <div id='ground-force' className='d-flex branch-container flex-column justify-content-start'>
                    <h1 className='sub-title header-shadow'><span className='branch-name'></span></h1>
                    <h4 className='branch-header'><span>No ground force, no gain</span></h4>
                    <p className='branch-body text-normal'>The Bobo Ground Force is composed of the strongest and most reliable warriors known to man. They are no strangers to fear, yet always face it head-on without hesitation. <br />Adaptable and decisive, members of the Ground Force are well-versed in a wide variety of land-combat weaponry, as well as weapons of mass destruction. </p>
                    <img alt='branch chief' className='branch-small-img d-block d-md-block d-lg-none' src={boboGFLeader} />
                </div>
                <div id='air-force' className='d-flex branch-container flex-column justify-content-center'>
                    <h1 className='sub-title header-shadow'><span className='branch-name float-left'></span></h1>
                    <h4 className='branch-header'><span className='float-left'>Bobo pilots = adorable explosions</span></h4>
                    <p className='branch-body text-normal'>Free-spirited explorers, daring risk-takers and affable competitors, members of the Bobo Air Force know that sometimes, rules are meant to be broken! <br />Imagination and creativity are the Air Force’s bread and butter, and they love their gadgets just as much as their aircrafts. </p>
                    <img alt='branch chief' className='branch-small-img d-block d-md-block d-lg-none' src={boboAFLeader} />
                </div>
                <div id='marine-corp' className='d-flex branch-container flex-column justify-content-center'>
                    <h1 className='sub-title header-shadow'><span className='branch-name'></span></h1>
                    <h4 className='branch-header'><span>The few. The proud. The Bobos.</span></h4>
                    <p className='branch-body text-normal'>When we say “the best, of the best, of the best,” we’re referring to the Bobo Marine Corp: an elite military force valuing courage and brotherhood as the highest qualities. <br />Prepared to dominate any sea they encounter, the Marines are specially trained to use maritime weapons, tools and armor. </p>
                    <img alt='branch chief' className='branch-small-img d-block d-md-block d-lg-none' src={boboMLeader} />
                </div>
                <div id='covert-ops' className='d-flex branch-container flex-column justify-content-center'>
                    <h1 className='sub-title header-shadow'><span className='branch-name float-left'></span></h1>
                    <h4 className='branch-header'><span className='float-left'>Target acquired, Ser</span></h4>
                    <p className='branch-body text-normal'>Behind every dependable military power is an even more dependable, super-secret military power backing them up! Enter: Bobo Covert Operations.<br />Schemers, plotters, and spies, members of the Bobo Covert Ops are masters of the arts of listening and waiting - and they aren’t afraid to get their hands dirty for the sake of the greater good.</p>
                    <img alt='branch chief' className='branch-small-img d-block d-md-block d-lg-none' src={boboCOLeader} />
                </div>
            </div>
        </div>
        
    )

}

export default BranchesGroup;