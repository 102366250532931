import React from 'react'
import './Origins.css'

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Modal from 'react-bootstrap/Modal';

import MainFooter from './MainFooter.js';

import fatherSecretFile from './images/frankoffSecretFile.webp'
import fatherImg from './images/frankoffPreview.webp'
import sonSecretFile from './images/dimitriSecretFile.webp'
import sonImg from './images/dimitriPreview.webp'

import intelligenceFile1_1 from './images/intelligence_file_1_1.webp'
import intelligenceFile1_2 from './images/intelligence_file_1_2.webp'
import intelligenceFile1_preview from './images/intelligence_file_preview.webp'

class Origins extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sections: ['characters', 'story'],
            currentSectionIndex: 0,
            showFather: false,
            showSon: false,
            originImages: [[intelligenceFile1_1, intelligenceFile1_2]],
            showOriginIndex: null,
        }
        this.switchSection = this.switchSection.bind(this)
        this.switchCharacterDetails = this.switchCharacterDetails.bind(this)
    }

    switchSection() {
        let newSection = (this.state.currentSectionIndex + 1 - 2) ** 2
        this.setState({currentSectionIndex: newSection})
    }

    switchCharacterDetails(character) {
        console.log(character)
        if(character === 'father') {
            this.setState((state) => ({showFather: !state.showFather}))
        } else {
            this.setState((state) => ({showSon: !state.showSon}))
        }
    }

    render() {
        return(
            <div id='origins-page' className='d-block'>
                <AwesomeSlider className='carousel sections-container' onTransitionEnd={this.switchSection}>
                    <div className='section-header pb-3'>
                        <img src={fatherSecretFile} className='section-bg d-block w-100' alt='characters section header' style={{height: '100%'}} />
                        <div className='section-text position-absolute d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='title title-shadow text-ussr'>Characters</h1>
                            <h4>Discover the characters taking part in the Bobos of War Metaverse's lore.</h4>
                        </div>
                    </div>
                    <div className='section-header pb-3'>
                        <img src={intelligenceFile1_1} className='section-bg d-block w-100' alt='story section header' />
                        <div className='section-text position-absolute d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='title title-shadow text-ussr'>Origin Story</h1>
                            <h4>The BIA files uncover many hidden secrets on the arrival of the Bobos Of War.</h4>
                        </div>
                    </div>
                </AwesomeSlider>


                <div id='section' className='container-fluid mt-5' style={{marginBottom: '20vh'}}>
                    {this.state.currentSectionIndex === 0 ?
                    <div id='characters-section' className='row d-flex justify-content-around'>
                        <div className='character-section col-10 col-sm-7 col-md-5 col-lg-3 col-xl-2 d-flex flex-column justify-content-start align-items-center'>
                            <img src={fatherImg} onClick={() => this.switchCharacterDetails('father')} className='character-img my-3' alt='frankoff' />
                        </div>
                        <Modal className='modal-secret-file' size="lg" centered show={this.state.showFather} onHide={() => this.switchCharacterDetails('father')}>
                            <Modal.Header closeButton closeVariant='lime'></Modal.Header>
                            <Modal.Body className='p-0'><img src={fatherSecretFile} alt='frankoff secret file' /></Modal.Body>
                        </Modal>

                        <div className='character-section col-10 col-sm-7 col-md-5 col-lg-3 col-xl-2 d-flex flex-column justify-content-start align-items-center'>
                            <img src={sonImg} onClick={() => this.switchCharacterDetails('son')} className='character-img my-3' alt='dimitri' />
                        </div>
                        <Modal className='modal-secret-file' size="lg" centered show={this.state.showSon} onHide={() => this.switchCharacterDetails('son')}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body className='p-0'><img src={sonSecretFile} alt='frankoff secret file' /></Modal.Body>
                        </Modal>
                    </div> :
                    <div id='story-section' className='row d-flex justify-content-center'>
                        <div className='story-section col-10 col-sm-7 col-md-5 col-lg-3 col-xl-2 d-flex flex-column justify-content-start align-items-center'>
                            <img src={intelligenceFile1_preview} onClick={() => this.setState({showOriginIndex: 0})} className='character-img my-3' alt='frankoff' />
                        </div>
                        <Modal id='intelligence-modal' className='modal-secret-file' size="lg" centered show={this.state.showOriginIndex !== null} onHide={() => this.setState({showOriginIndex: null})}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body className='p-0'>
                                {this.state.showOriginIndex !== null ? this.state.originImages[this.state.showOriginIndex].map(imageSrc => {
                                    return (
                                        <img src={imageSrc} className='mb-3' alt='frankoff secret file' />
                                    )
                                }) : ''}
                                
                            </Modal.Body>
                        </Modal>
                    </div>}
                </div>

                <MainFooter links={this.props.links}  />
            </div>
        )
    }
}

export default Origins