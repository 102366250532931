import React, { useEffect } from "react";

import "./AnimationsPresentation.css";
import {sketchImagesContainer1, sketchImagesContainer2} from "../sketchesImport.js"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AnimationsPresentation = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let previewSketchesTl = gsap.timeline({
            scrollTrigger: {
                trigger: "#animations-presentation-banner",
                start: "top 60%", 
                end: "bottom top",
                scrub: 1,
            }
        })

        previewSketchesTl.to("#preview-sketches1", {yPercent: 100, xPercent: -100})
        previewSketchesTl.to("#preview-sketches2", {yPercent: 100, xPercent: 100}, '<')
    });

    return(
        <div id='animations-presentation-banner' className='d-flex flex-column justify-content-center align-items-center'>
            <div id='preview-sketches-container' className='d-flex justify-content-center align-items-center'>
                <div id='preview-sketches1' className='d-flex position-absolute'>
                    {sketchImagesContainer1.map(image => {
                        return(
                            <div className='col-6 col-md-4 col-lg-3'>
                                <LazyLoadImage src={image} effect="blur" className='preview-sketch' wrapperProps={{style: {width: "100%", height: "100%"}}} />
                            </div>
                        )
                    })}
                </div>
                <div id='preview-sketches2' className='d-flex position-absolute justify-content-end'>
                    {sketchImagesContainer2.map(image => {
                        return(
                            <div className='col-6 col-md-4 col-lg-3'>
                                <LazyLoadImage src={image} effect="blur" className='preview-sketch' wrapperProps={{style: {width: "100%", height: "100%"}}} />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='d-flex text-container flex-column justify-content-around align-items-center' style={{height: "80%"}}>
                <h1 className='title title-shadow text-ussr' style={{fontSize: '6vmax'}}>Animation Series</h1>
                <a href='/series' className='badge-pill badge-dark no-decoration selected-gradient px-3 py-2'><h2 className='text-ussr mb-0' style={{letterSpacing: '0.1em'}}><b>View Sketches</b></h2></a>
            </div>
        </div>
    )
}

export default AnimationsPresentation;